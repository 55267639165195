const account 		= (state) => {
	return state.account
}

const token 		= (state) => {
	return state.token
}

const check 		= (state) => {
	return !! state.token;
}

const permissions 	= (state) => {
	return state.permissions
}

export {
	account,
	token,
	check,
	permissions
}