const UPDATE_ACCOUNT 		= (state, payload) => {
	state.account				= payload ? {...payload} : {}
}

const UPDATE_PERMISSIONS 	= (state, payload) => {
	state.permissions			= payload ? [...payload] : []
}

const UPDATE_TOKEN 			= (state, payload) => {
	state.token 				= payload
}

const UPDATE_ENVIRONMENT 	= (state, payload) => {
	state.environment			= payload
}

export {
	UPDATE_ACCOUNT,
	UPDATE_TOKEN,
	UPDATE_ENVIRONMENT,
	UPDATE_PERMISSIONS
}