export default {
	requestLoading:		0,
	all_plans:			[],
	selected_plan:		{},	
	menu_options: 		[
		{ role: 1, text: 'O que é', route: '#whatis', blank: false},
		{ role: 2, text: 'Planos', route: '#plans', blank: false},
		{ role: 3, text: 'Clientes', route: '#testimony', blank: false},
		{ role: 4, text: 'Quem somos', route: '#who', blank: false},
		{ role: 5, text: 'FAQ', route: process.env.VUE_APP_FAQ_URL, blank: true },
		{ role: 6, text: 'Contato', route: process.env.VUE_APP_CONTACT_URL, blank: true },
		{ role: 7, text: 'Login', route: '/login', blank: true },
		{ role: 8, text: 'Cadastre-se', route: '/cadastro', blank: true },
	],
}