window.statesBR 			= [
	{"id":1,"initials":"AC","name":"Acre"},
	{"id":2,"initials":"AL","name":"Alagoas"},
	{"id":3,"initials":"AP","name":"Amapá"},
	{"id":4,"initials":"AM","name":"Amazonas"},
	{"id":5,"initials":"BA","name":"Bahia"},
	{"id":6,"initials":"CE","name":"Ceará"},
	{"id":7,"initials":"DF","name":"Distrito Federal"},
	{"id":8,"initials":"ES","name":"Espírito Santo"},
	{"id":9,"initials":"GO","name":"Goiás"},
	{"id":10,"initials":"MA","name":"Maranhão"},
	{"id":11,"initials":"MT","name":"Mato Grosso"},
	{"id":12,"initials":"MS","name":"Mato Grosso do Sul"},
	{"id":13,"initials":"MG","name":"Minas Gerais"},
	{"id":14,"initials":"PA","name":"Pará"},
	{"id":15,"initials":"PB","name":"Paraíba"},
	{"id":16,"initials":"PR","name":"Paraná"},
	{"id":17,"initials":"PE","name":"Pernambuco"},
	{"id":18,"initials":"PI","name":"Piauí"},
	{"id":19,"initials":"RJ","name":"Rio de Janeiro"},
	{"id":20,"initials":"RN","name":"Rio Grande do Norte"},
	{"id":21,"initials":"RS","name":"Rio Grande do Sul"},
	{"id":22,"initials":"RO","name":"Rondônia"},
	{"id":23,"initials":"RR","name":"Roraima"},
	{"id":24,"initials":"SC","name":"Santa Catarina"},
	{"id":25,"initials":"SP","name":"São Paulo"},
	{"id":26,"initials":"SE","name":"Sergipe"},
	{"id":27,"initials":"TO","name":"Tocantins"}
]