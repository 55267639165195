<template>
    <!--navigation-->
    <nav 
        id="navbar"
        class="navbar navbar-expand-md navbar-light sticky-top bg-nav px-0"
        :class="{ 'active bg-white': activeClass }"
        v-scroll="HandleActiveClass"
    >
        <div class="col-12 d-flex align-items-center justify-content-around justify-content-lg-center"
            data-aos="fade-down">
            <!--logo-->
            <router-link 
                class="navbar-brand mr-5 mr-lg-5" 
                :to="{ name: 'Home'}"
            >
                <img class="logo" :src="logo">
            </router-link>
            <!--menu button collapse-->
            <button 
                class="ml-5 navbar-toggler" 
                id="button-toggler-shadow" 
                type="button"
                data-toggle="collapse" 
                data-target="#navbarResponsive"
            >
                <span class="navbar-toggler-icon"></span>
            </button>
            <!--links navbar-->
            <div v-if="!small_tab" class="collapse navbar-collapse nav-links ml-lg-5" id="navbarResponsive">
                <!--texts and button-->
                <ul class="navbar-nav d-flex align-items-center mx-lg-4 ul-nav">
                    <li v-for="menu in menu_options" :key="menu.role"
                        class="my-2 mx-1 mx-lg-3">
                        <a :href="generateLink(menu.route)"
                            :target="menu.blank ? '_blank' : ''"
                            class="text-center text-primary navbar-menu p-2"
                            :class="menu.route == '/login' ? ' login-button' : ''"
                            @click.prevent="scrollTo(menu.route, menu.blank)"
                        >
                            {{ menu.text }}
                        </a>
                    </li>
                </ul>
            </div>
        </div>
        <div v-if="small_tab"
            class="collapse navbar-collapse nav-links ml-lg-5" id="navbarResponsive">
            <!--texts and button-->
            <ul class="navbar-nav d-flex align-items-center mx-lg-4 ul-nav">
                <li v-for="menu in menu_options" :key="menu.role"
                    class="my-2 mx-1 mx-lg-3">
                    <a :href="generateLink(menu.route)"
                        :target="menu.blank ? '_blank' : ''"
                        class="text-center text-primary navbar-menu p-2"
                        :class="menu.route == '/login' ? ' login-button' : ''"
                        @click.prevent="scrollTo(menu.route, menu.blank)"
                    >
                        {{ menu.text }}
                    </a>
                </li>
            </ul>
        </div>
    </nav>
</template>

<script>
import { mapGetters } from 'vuex'
	export default {

		name: 'Navbar',

		data () {
			return {
                logo:               require('../../../assets/img/1-2-logo-vertical-shadow.png'),
                activeClass:        false,
                tabsize:            0
			}
		},
        computed: {
            ...mapGetters('system', [
                'menu_options'
            ]),
            faq_url()
            {
                return process.env.VUE_FAQ_URL
            },
            small_tab()
            {
                return this.tabsize < 768
            }
        },
        methods: {
            HandleActiveClass(e)
            {   
                const scrollTop     = e.target.scrollingElement.scrollTop

                this.activeClass    = scrollTop > 40
            },
            generateLink(route)
            {
                // if(!route.includes('http')) return
                return route
            },
			scrollTo(element, blank = false)
			{
                if(element[0] === '/')
                {
                    if(this.$route.path == element) return
                    
                    this.$router.push(element)
                    
                    if(this.small_tab)
                    {
                        document.querySelector('#button-toggler-shadow').click()
                    }
                    
                    return
                }

                if(element.includes('http'))
                {
                    if(blank)
                    {
                        window.open(element)
                        
                        return
                    } else {
                        window.location = element
                    }
                }

                if(document.querySelector(element)) {
                    // window.scrollTo(element)

                    if(this.small_tab)
                    {
                        document.querySelector('#button-toggler-shadow').click()
                    }
                    
                    return
                }

                this.$router.push({ name: 'Home', params: { redirectTo: element } })
			}
        },
        mounted()
        {
            this.tabsize = window.innerWidth

            window.addEventListener('resize', () => {
				this.tabsize = window.innerWidth
			})
        }
	}
</script>

<style lang="css" scoped>
.navbar-collapse {
    flex-grow: unset !important;
}

.login-button {
    border: solid 1px var(--primary);
    border-radius: 10px;
    background-color: var(--primary);
    color: #fff !important;
}

.login-button:hover {
    color: #fff !important;
}
</style>