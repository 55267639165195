const _ = require('lodash')

const StartLoading = ({ commit }) => {
	commit('ADD')
}

const FinishLoading = ({ commit }) => {
	commit('REM')
}

const GetAllPlans = async ({ commit, state }, payload) => {

	return await window.api.call('get', '/website/get-plans')
		.then(({data}) => {
			if(data.response)
			{
				let all_plans = data.plans

				
				all_plans = all_plans.map((p) => {
					p.benefits = JSON.parse(p.description).benefits
					p.description = JSON.parse(p.description).description
					return p
				})
				
				all_plans = _.orderBy(all_plans, [(p) => {
					return p.total_price
				}], 'asc')
				
				commit("UPDATE_ALL_PLANS", all_plans)
			}
		})
}

const SetSelectedPlan = ({ commit }, payload) => {
	commit("UPDATE_SELECTED_PLAN", payload)
}

export {
	StartLoading,
	FinishLoading,
	GetAllPlans,
	SetSelectedPlan
}