import Vuex from './vuex'

class Api {
    constructor () {}

    call (requestType, url, data = null, opts = {}) {

    	const axios 		= window.axios.create()

    	delete axios.defaults.headers.common["X-Socket-Id"]

	    return new Promise((resolve, reject) => {
	        axios[requestType](url, data, opts)
	            .then(response => {
	                resolve(response)
	            })
	            .catch(({response}) => {
	                resolve(response)
	            });
	    });
	}
}

export default Api;