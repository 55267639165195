require('./bootstrap')

import Vue from 'vue'

import Api from './api'
window.api      = new Api()

import router from './routes'

import App from './Index'

import store from './vuex'


/* eslint-disable no-unused-vars */
const app = new Vue({
    el: '#app',
    router,
    store,
    render: h => h(App)
})
/* eslint-enable no-unused-vars */