<template>
	<footer>
    	<div class="container">
	    	<div class="row div-footer-style col-12 d-flex justify-content-center">
		        
		        <!--links about instant medical-->
		        <div class="col-6 col-lg-2 links-style-1">
		            <p class="titles-footer">
		                <b>
		                    Instant Medical
		                </b>
		            </p>
		            <p v-for="menu in menu_parse" :key="menu.role">
		                <a href="#plans"
							@click.prevent="scrollTo(menu.route, menu.blank)">
		                    {{ menu.text }}
		                </a>
		            </p>
		        </div>
		        <!--useful links-->
		        <div class="col-6 col-lg-2 links-style-2">
		            <p class="titles-footer">
		                <b>
		                    Links Úteis
		                </b>
		            </p>
		            <p>
		                <a :href="faq_url" target="_blank">
		                    FAQ
		                </a>
		            </p>
		            <p>
		                <a :href="contact_url" target="_blank">
		                    Contato
		                </a>
		            </p>
		            <p>
		                <router-link :to="{ name: 'ServiceTermsIndex' }">
		                    Termos de Serviço
		                </router-link>
		            </p>
		            <p>
		                <router-link :to="{ name: 'PrivacyPolicyIndex' }">
		                    Politica de Privacidade
		                </router-link>
		            </p>
		        </div>
		        <!--adress and phones links-->
		        <div class="col-lg-2 links-style-2 links-style-5">
		            <p class="titles-footer">
		                <b>
		                    Onde Estamos
		                </b>
		            </p>
					<div class="mb-1">
						<p class="mb-1">
							Rua Major Emídio de Castro, 701 - Ramal 28
							<br/>
						</p>
						São José do Rio Preto-SP
					</div>
		            <!--phone-->
		            <p>
		                <img class="telephone-img" :src="phone_img">
						<a href="tel:11930463079">
							<b class="telephone">
								(11) 93046-3079
							</b>
						</a>
		            </p>
		            <p>
		                <img class="telephone-img" :src="phone_img">
						<a href="tel:1732344464">
							<b class="telephone">
								(17) 3234-4464
							</b>
						</a>
		            </p>
		        </div>
		        
		        <div class="col-12 style-copyright">
			        <p>
			            {{ copyright }}
			        </p>
		        </div>         
		    </div>
		</div>
	</footer>
</template>

<script>
	import { mapGetters } from 'vuex'
	export default {

		name: 'Foot',

		data () {
			return {
				logo_facebook: 	require('../../../assets/img/logo-facebook.png'),
				logo_youtube: 	require('../../../assets/img/logo-youtube.png'),
				logo_linkedin: 	require('../../../assets/img/logo-linkedin.png'),				
				logo_instagram: require('../../../assets/img/logo-instagram.png'),
				logo_vhc: 		require('../../../assets/img/logo-visual-health-care.png'),
				phone_img: 		require('../../../assets/img/telephone.png'),
				copyright: 		`${new Date().getFullYear()} - Instant Medical`
			}
		},
		computed: {
			...mapGetters('system', [
				'menu_options'
			]),
			menu_parse()
			{
				return this.menu_options.filter(e => e.route.includes('#'))
			},
			faq_url()
			{
				return process.env.VUE_APP_FAQ_URL
			},
			contact_url()
			{
				return process.env.VUE_APP_CONTACT_URL
			}
		},
		methods: {
			scrollTo(element, blank = false)
			{
                if(element.includes('#'))
                {
                    // window.scrollTo(element)

                    if(this.small_tab)
                    {
                        document.querySelector('#button-toggler-shadow').click()
                    }
                    
                    return
                }

                if(blank)
                {
                    window.open(element)
                    
                    return
                }
                
                window.location = element
			}
		}
	}
</script>

<style lang="css" scoped>
</style>