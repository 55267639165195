<template>
  <div class="d-flex justify-content-center align-items-center vh-80 vw-100">
	<p class="loading">
		<b>redirecionando</b>
	</p>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import Swal from 'sweetalert2'
export default {
	name: 'EmailValidation',
	props: {
		token: {
			type: String,
			required: true
		}
	},
	data() {
		return {
			
		}
	},
	methods: {
		...mapActions('system', [
			'StartLoading', 'FinishLoading'
		]),
		async Validate() {
			const vm = this

			vm.StartLoading()

			return window.api.call('post', '/website/validate-new-email', {
				token: vm.token,
			})
				.then(async ({data}) => {
					if(data.response) {
						Swal.fire({
							icon: 	'success',
			        		title: 	'Pronto',
							html: 	'Seu e-mail foi validado com sucesso.<br><br>Fechar esta aba?',
							confirmButtonText: 'Sim'
						})
							.then(() => {
								window.close()
							});

						return
					}

					Swal.fire({
						icon: 	'error',
		        		title: 	'Ops',
						text: 	'Não foi possível validar o seu e-mail.',
					})
				})
				.finally(() => {
					vm.FinishLoading()
				})
		}
	},
	async beforeMount() {
		await this.Validate()
	}
}
</script>

<style lang="css" scoped>
.loading {
	width: 7%;
}

.loading:after {
  overflow: hidden;
  display: inline-block;
  vertical-align: bottom;   
  animation: ellipsis steps(4,end) 1500ms infinite;
  content: "\2026";
  width: 0px;
}

@keyframes ellipsis {
  to {
    width: 1em;    
  }
}
</style>