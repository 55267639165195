<template>
	<div id="container-instant" class="col-10 col-md-8 col-lg-6 col-xl-4 d-flex justify-content-center py-4 p-md-4 p-lg-5 h-800" v-if="account">
		<transition name="fade" mode="out-in">
			<div class="col-12 d-flex flex-column align-items-center justify-content-center">
				<div class="d-flex justify-content-center align-items-center logo-container mb-5">
					<img class="logo" :src="logo_url" alt="logo-instant">
				</div>
				<div v-show="step === 1" class="col form">
					<div class="form-group">
						<label for="name">Nome completo</label>
						<input 
							type="text"
							id="name"
							class="form-control"
							:class="{ 'is-invalid': $v.form.name.$error }"
							v-model="$v.form.name.$model"
							autocomplete="new-name"
						>
						<div class="invalid-feedback">
							Obrigatório
						</div>
					</div>
					<div class="form-group">
						<label for="birth_date">Data de Nascimento</label>
						<input 
							type="phone"
							id="birth_date"
							v-mask="[ '##/##/####' ]"
							class="form-control"
							:class="{ 'is-invalid': $v.form.birth_date.$error }"
							v-model="$v.form.birth_date.$model"
							placeholder="DD/MM/AAAA"
							autocomplete="new-birth_date"
						>
						<div class="invalid-feedback" v-if="!$v.form.birth_date.required">
							Obrigatório
						</div>
						<div class="invalid-feedback" v-else-if="!$v.form.birth_date.minValue">
							Deve ser posterior à 01/01/1930
						</div>
						<div class="invalid-feedback" v-else-if="!$v.form.birth_date.maxValue">
							Deve ser anterior à {{ today_minus_18_years }}
						</div>
					</div>
					<div class="form-group">
						<label for="cpf">CPF</label>
						<input 
							type="phone"
							id="cpf"
							v-mask="['###.###.###-##']"
							class="form-control"
							:class="{ 'is-invalid': $v.form.cpf.$error }"
							v-model="$v.form.cpf.$model"
							autocomplete="username"
						>
						<div class="invalid-feedback" v-if="!$v.form.cpf.required">
							Obrigatório
						</div>
						<div class="invalid-feedback" v-else-if="!$v.form.cpf.CPFValidation">
							CPF Inválido
						</div>
					</div>
					<div class="form-group">
						<label for="cel_phone">Celular</label>
						<input 
							type="phone"
							id="cel_phone"
							v-mask="[ '(##) ####-####', '(##) #####-####' ]"
							class="form-control"
							:class="{ 'is-invalid': $v.form.cel_phone.$error }"
							v-model="$v.form.cel_phone.$model"
							autocomplete="new-cel_phone"
						>
						<div class="invalid-feedback">
							Obrigatório
						</div>
					</div>
					<div class="form-group">
						<label for="password">Senha</label>
						<input 
							type="password"
							id="password"
							class="form-control"
							:class="{ 'is-invalid': $v.form.password.$error }"
							v-model="$v.form.password.$model"
							autocomplete="new-password"
						>
						<div class="capslock-alert" v-if="capslock_on">
							CAPSLOCK ligado!
						</div>
						<div class="invalid-feedback" v-if="!$v.form.password.required">
							Obrigatório
						</div>
						<div class="invalid-feedback" v-else-if="!$v.form.password.minLength">
							Mínimo de 6 caractéres
						</div>
					</div>
					<div class="form-group d-flex align-items-center">
						<input 
							type="checkbox"
							id="is_doctor"
							class="form-control ml-1 mr-2"
							v-model="is_doctor"
						>
						<label for="is_doctor">Sou médico</label>
					</div>
				</div>
				<div v-show="step === 2" class="col doctor-form">
					<div class="form-group">
						<label for="title">Título</label>
						<select 
							id="title" 
							name="title"
							class="col"
							:class="{ 'is-invalid': $v.doctor_form.title.$error }"
							v-model="$v.doctor_form.title.$model"
							autocomplete="new-title"
						>
							<option value="" selected>Selecione...</option>
							<option value="Dr.">Dr.</option>
							<option value="Dra.">Dra.</option>
						</select>
						<div class="invalid-feedback">
							Obrigatório
						</div>
					</div>
					<div class="form-group">
						<label for="name_stamp">Nome</label>
						<input 
							type="text"
							id="name_stamp"
							class="form-control"
							:class="{ 'is-invalid': $v.doctor_form.name_stamp.$error }"
							v-model="$v.doctor_form.name_stamp.$model"
							autocomplete="new-name_stamp"
						>
						<div class="invalid-feedback">
							Obrigatório
						</div>
					</div>
					<div class="form-group">
						<label for="crm">CRM</label>
						<input 
							type="text"
							id="crm"
							class="form-control"
							v-mask="['######']"
							:class="{ 'is-invalid': $v.doctor_form.crm.$error }"
							v-model="$v.doctor_form.crm.$model"
							autocomplete="new-crm"
						>
						<div class="invalid-feedback" v-if="!$v.doctor_form.crm.required">
							Obrigatório
						</div>
					</div>
					<div class="form-group">
						<label for="uf">UF</label>
						<select
							id="uf"
							name="uf"
							class="col"
							:class="{ 'is-invalid': $v.doctor_form.uf.$error }"
							v-model="$v.doctor_form.uf.$model"
							autocomplete="new-uf"
						>
							<option value="" selected>Selecione...</option>
							<option v-for="(uf, index) in ufs" 
								:value="uf.initials" :key="index">{{ uf.initials }} - {{ uf.name }}</option>
						</select>
						<div class="invalid-feedback">
							Obrigatório
						</div>
					</div>
					<div class="form-group">
						<label for="specialty">Especialidade</label>
						<input 
							type="text"
							id="specialty"
							class="form-control"
							:class="{ 'is-invalid': $v.doctor_form.specialty.$error }"
							v-model="$v.doctor_form.specialty.$model"
							autocomplete="new-specialty"
						>
						<div class="invalid-feedback">
							Obrigatório
						</div>
					</div>
				</div>
				<div class="col d-flex flex-column align-items-center">
					<button class="btn btn-primary py-2 my-3"
						@click="NextStep()">
						<span class="text-uppercase">
							Continuar
						</span>
					</button>
					<span class="" v-if="step !== 1">
						<a class="link" @click="BackStep()">voltar</a>
					</span>
				</div>
			</div>
		</transition>
	</div>
</template>

<script>

	import Swal from 'sweetalert2'
	import { required, minLength, sameAs, requiredIf } from 'vuelidate/lib/validators'
	import { mapActions } from 'vuex'
	import moment from 'moment'
	const CPFValidation = (value, vm) => window.helpers.ValidaCPF(value)
	export default {

		name: 'Index',
		props: {
			token: 	{
				type: 		[String, Number],
				default: 	null
			}
		},
		data () {
			return {
				defaultAvatar: 		require('../../assets/img/profile.png'),
				step: 				1,
				account: 			null,
				exihibitAvatar: 	null,
				form: 				{
					name: 					'',
					birth_date: 			'',
					cpf: 					'',
					cel_phone: 				'',
					password: 				'',
				},
				is_doctor: 					false,
				doctor_form:		{
					title: 					'',
					name_stamp: 			'',
					crm: 					'',
					uf: 					'',
					specialty: 				'',
				},
				capslock_on:		false,
				logo_url:			window.logo_url,
				ufs:				window.statesBR,
			}
		},
		validations() {
			let obj = {
				form: 			{
					name: 			{
						required
					},
					birth_date: 	{
						required,
						minValue: 	(val) => {
							let validate = false

							if(val) {
								const year_regex = val.match(/\/\d{4}$/g)
								const year = year_regex && year_regex.length ? year_regex[0].slice(-4) : null
	
								if(year && year !== '0000') {
									const value = moment(val, 'DD/MM/YYYY')
									const minDate = moment('1930-01-01')
									
									validate = value > minDate
								}
							}
								
							return validate
						},
						maxValue: 	(val) => {
							let validate = false

							if(val) {
								validate = moment(val, 'DD/MM/YYYY') < moment().subtract(18, 'years')
							}

							return validate
						}
					},
					cpf: 			{
						required,
						CPFValidation //if
					},
					cel_phone: 		{
						required
					},
					password: 		{
						required,
						minLength: 	minLength(6)
					},
				},
				doctor_form: {
					title: 			{
						required: 	requiredIf(() => this.is_doctor)
					},
					name_stamp: 	{
						required: 	requiredIf(() => this.is_doctor)
					},
					crm: 			{
						required: 	requiredIf(() => this.is_doctor)
					},
					uf: 			{
						required: 	requiredIf(() => this.is_doctor)
					},
					specialty: 		{
						required: 	requiredIf(() => this.is_doctor)
					},
				}
			}

			return obj;
		},
		computed: {
			today_minus_18_years() {
				return moment().subtract(18, 'years').format('DD/MM/YYYY')
			},
		},
		watch: {
			'form.name': {
				handler(val) {
					if(val) {
						this.doctor_form.name_stamp = val
					}
				},
			}
		},
		methods: {
			...mapActions('system', [
				'StartLoading', 'FinishLoading'
			]),
			BackStep() {
				switch(this.step) {
					case 1: 
					break
					case 2:
						this.step 	= 1
					break;
				}
			},
			async NextStep() {
				switch(this.step) {
					case 1: 
						this.$v.form.$touch()

						if(!this.$v.form.$invalid) {
							if(this.is_doctor) {
								this.step = 2;

								return;
							}

							await this.Save();
						}
					break;
					case 2:
						this.$v.doctor_form.$touch()

						if(!this.$v.doctor_form.$invalid) {
							await this.Save();
						}
					break;
				}
			},
			GetAccountByToken()
			{
				const vm = this

				vm.StartLoading()

				return window.api.call('post', '/website/get-account-by-token', {
						token: 	this.token,
						type: 	'api'
					})
					.then(({data}) => {

						if(data.response)
						{	
							this.account 		= data.account

							this.form.name 		= data.account.name

						}else{

							Swal.fire({
								icon: 				'error',
								title: 				'Ops...',
								text: 				'Houve um erro ao obter, contate o suporte.',
								showConfirmButton: 	true,
								preConfirm: () => {
									this.$router.push({ name: 'Login' })
								}
							})
						}
					})
					.finally(() => {
						vm.FinishLoading()
					})
					/*.catch(({resp}) => {

						Swal.fire({
							icon: 				'error',
							title: 				'Ops...',
							text: 				'Token inválido, contate o suporte.',
							showConfirmButton: 	true,
							preConfirm: () => {
									this.$router.push({ name: 'Login' })
							}
						})
					})*/
			},
			RemAvatar()
			{
				this.form.avatar.file 	= ''
				this.form.avatar.name 	= ''

				this.exihibitAvatar     = null
			},
			GetAvatar(event)
			{
            	const reader 			= new FileReader()
	            const me 				= this
	            let file 				= event.target.files[0]

	            if(file)
	            {
	            	Swal.showLoading()

	            	this.form.avatar.file	= file
		            this.form.avatar.name	= file.name

		            reader.onload 			= e => me.exihibitAvatar = e.target.result

		            reader.readAsDataURL(file)

		            Swal.close()
	            }
			},
			async Save()
			{
				Swal.showLoading()

				// const formData 		= new FormData()

				// if(this.exihibitAvatar) {
				// 	formData.append('avatar', this.form.avatar.file, this.form.avatar.name)
				// }

				this.form 			= {
					...this.form,
					...this.doctor_form
				};

				this.form.is_doctor = this.is_doctor;

				// await Object.keys(this.form).map((key, index) => {
				// 	if(key != 'avatar') {
				// 		formData.append(key.toString(), this.form[key])
				// 	}
				// })

				// formData.append('account_id', this.account.id)

				window.axios.post(`/website/complete-registration`, {
					account: { 
						...this.form,
						id: this.account.id
					}
				})
					.then(({data}) => {
						if(data.response)
						{
							Swal.fire({
								icon: 				'success',
								title: 				'Pronto !',
								text: 				'Agora você possui acesso ao sistema e será redirecionado.',
								showConfirmButton: 	true,
								preConfirm: () => {
									window.location.href = `${process.env.VUE_APP_MANAGEMENT_URL}/auth/token/${data.bridge_key}`
								}
							})
						} else {
							let message 		= 'Houve um erro ao concluir, contate o suporte.'

							if(data.exists) {
								switch(data.exists) {
									case 'document':
										message 	= 'Este CPF já esta cadastrado, verique se ja possui cadastro com outro email.'
									break
									case 'fone':
										message 	= 'Este telefone já esta cadastrado, verique se ja possui cadastro com outro email.'
									break
								}
							}

							Swal.fire({
								icon: 				'error',
								title: 				'Ops...',
								text: 				message
							})
						}
					})
					.catch(({response}) => {
						Swal.fire({
							icon: 				'error',
							title: 				'Ops...',
							text: 				'Houve um erro ao concluir, contate o suporte.'
						})
					})
			}
		},
		beforeMount()
		{
			document.addEventListener('keyup', (e) => {
				if (e.getModifierState && e.getModifierState('CapsLock')) {
					this.capslock_on = true
				} else {
					this.capslock_on = false
				}
			});

			this.GetAccountByToken()
		}
	}
</script>

<style lang="css" scoped>
input#is_doctor {
	width: 1.2rem;
}

#container-instant .form .form-group {

}

#container-instant .doctor-form .form-group {
	margin-bottom: 1.6rem;
}

select {
	background-color: #E5E5E5;
	height: calc(1.5em + 0.75rem + 3px);
	border: 1.5px solid #ccc;
	outline: 1.5px solid #ccc;
    border-radius: 8px;
	padding-left: 8px;
}

.btn-primary {
	height: 38.8px;
	width: 100%;
}
</style>