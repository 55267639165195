<template>
    <NewEntry
		ref="entry"
		type="register"
		:height="700"
	/>
</template>

<script>
import NewEntry from '../home/sections/NewEntry'
export default {
    components: {
        NewEntry
    }
}
</script>

<style>

</style>