<template>
	<NewEntry
		ref="entry"
		type="login"
		:height="700"
		:bridge_key="bridge_key"
	/>
</template>

<script>
import NewEntry from '../home/sections/NewEntry'
export default {
    components: {
        NewEntry
    },
	props: {
		bridge_key:	{
			type:		String,
			default:	null
		}
	}
}
</script>

<style lang="css" scoped>
	
</style>