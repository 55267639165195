import Vue from 'vue'

Vue.directive('scroll', {
	inserted (el, binding) 
	{
		let f 		= (evt) => {
			if (binding.value(evt, el)) 
			{
				window.removeEventListener('scroll', f)
			}
		}
		
		window.addEventListener('scroll', f)
	}
})

// window.scrollTo = (element) => {

	// const el = document.querySelector(element)

	// if(el)
	// {
	// 	const nav_height = document.querySelector('#navbar').offsetHeight
		
	// 	window.scroll(0, (el.offsetTop - nav_height))
	// }
// }