<template>
	<div id="container-instant" class="col-10 col-md-8 col-lg-6 col-xl-4 d-flex justify-content-center py-4 p-md-4 p-lg-5 h-700">
		<div class="col-12 d-flex flex-column align-items-center">
			<div class="d-flex justify-content-center align-items-center logo-container mb-5">
				<img class="logo" :src="logo_url" alt="logo-instant">
			</div>
			<div class="col-12" v-if="account">
				<div class="col-12 mt-5">
					<div class="form-group">
						<label for="password">Informe sua nova senha</label>
						<input 
							id="password"
							type="password"
							class="form-control"
							:class="{ 'is-invalid': $v.form.password.$error }"
							v-model="$v.form.password.$model"
							@keyup.13="Save"
						>
						<div class="invalid-feedback" v-if="!$v.form.password.required">
							Obrigatório
						</div>
						<div class="invalid-feedback" v-if="!$v.form.password.minLength">
							Minimo de 6 caracteres
						</div>
					</div>
				</div>
				<div class="col-12 mt-5">
					<div class="form-group">
						
						<label for="password">Confirme sua nova senha</label>
						<input 
							id="password"
							type="password"
							class="form-control"
							:class="{ 'is-invalid': $v.form.password_confirmation.$error }"
							v-model="$v.form.password_confirmation.$model"
							@keyup.13="Save"
						>
						<div class="invalid-feedback">
							Você deve informar a mesma senha
						</div>
					</div>
				</div>
				<div class="col-12 text-center mb-4 mt-5">
					<button
						type="button"
						class="col-12 btn btn-primary py-2 mt-5 text-uppercase"
						@click="Save"
					>
						Continuar
					</button>
				</div>
				<div clas="col-12">
					<p class="text-center m-0 mt-3">
						<router-link :to="{ name: 'Login'}">Voltar</router-link>
					</p>
				</div>
			</div>
			<div class="col-12" v-else>
				<div class="col-12">
					<h5 class="mt-3 title-1 text-center mb-5">Validando...</h5>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

	import Swal from 'sweetalert2'
	import { required, minLength, sameAs, requiredIf } from 'vuelidate/lib/validators'
	const api_url 		= process.env.VUE_APP_API_URL
	export default {

		name: 'Index',
		props: {
			token: 	{
				type: 		String,
				default: 	null
			},
			bridge_key:	{
				type:		String,
				default:	null
			}
		},
		data () {
			return {
				account: 			null,
				form: 				{
					password: 					'',
					account_id: 				'',
					password_confirmation: 		''
				},
				logo_url: 			window.logo_url
			}
		},
		validations: {
			form: 		{
				password: 	{
					required,
					minLength: minLength(6)
				},
				password_confirmation: {
					sameAsPassword: sameAs('password')
				}
			}
		},
		methods: {
			GetAccountByToken() {
				Swal.showLoading()

				return window.api.call('post', '/website/get-account-by-token',{
						token: 	this.token,
						type: 	'api'
					})
					.then(({data}) => {
						if(data.response) {	
							Swal.close()

							this.account 			= data.account
							this.form.account_id 	= data.account.id
						} else {
							Swal.fire({
								icon: 				'error',
								title: 				'Ops...',
								text: 				'Houve um erro ao obter, contate o suporte.',
								showConfirmButton: 	true,
								preConfirm: () => {
									this.$router.push({ name: 'Login' })
								}
							})
						}
					})
					.catch(({resp}) => {
						Swal.fire({
							icon: 				'error',
							title: 				'Ops...',
							text: 				'Token inválido, contate o suporte.',
							showConfirmButton: 	true,
							preConfirm: () => {
								this.$router.push({ name: 'Login' })
							}
						})
					})
			},
			Save() {
				this.$v.form.$touch()

				if(!this.$v.form.$invalid) {
					Swal.showLoading()

					window.api.call('post', '/website/finish-recovery-password', this.form)
						.then(({data}) => {
							if(data.response) {
								Swal.fire({
									icon: 				'success',
									title: 				'Pronto !',
									text: 				'Nova senha cadastrada com sucesso.',
									showConfirmButton: 	true,
									preConfirm: () => {
										this.$router.push({ name: 'Login', params: { bridge_key: this.bridge_key } })
									}
								})
							} else {

								Swal.fire({
									icon: 				'error',
									title: 				'Ops...',
									text: 				'Houve um erro ao concluir, contate o suporte.'
								})
							}
						})
						.catch(({response}) => {
							Swal.fire({
								icon: 				'error',
								title: 				'Ops...',
								text: 				'Houve um erro ao concluir, contate o suporte.'
							})
						})
				}
			}
		},
		beforeMount() {
			this.GetAccountByToken()
		}
	}
</script>

<style lang="css" scoped>


</style>