import router from '../../routes'

const api_url 	= process.env.VUE_APP_API_URL

const OAuth 	= ({ commit }, payload) => {
	return new Promise((resolve, reject) => {
		window.axios.post(`${api_url}/api/website/oauth`, payload)
		.then(({data}) => {

			if(data.response)
			{
				resolve(data)

			}else{

				reject(data)
			}

		}).catch(({response}) => {

			reject(response)

		})
	})
}

const Login = ({ commit }, payload) => {

	return new Promise((resolve, reject) => {

		window.axios.post(`${api_url}/api/website/login`, {
			username: payload.username,
			password: payload.password
		})
		.then(({data}) => {

			if(data.response)
			{
				resolve(data)

			}else{

				reject(data)
			}
		})
		.catch(({response}) => {

			reject(response)
		})

	})
}


export {
	Login,
	OAuth
}