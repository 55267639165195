import Vue from 'vue'

window._ 			= require('lodash')

window.axios        = require('axios');

window.axios.defaults.headers.common['X-Requested-With']   = 'XMLHttpRequest';

window.logo_url		= require('./assets/img/logo_original.png');

const authToken = localStorage.getItem('token');

if (authToken) 
{
    window.axios.defaults.headers.common['Authorization']     = 'Bearer ' + authToken;
}

window.axios.defaults.baseURL = `${process.env.VUE_APP_API_URL}/api`

try {
    window.Popper 	= require('popper.js');
    window.$ 		= window.jQuery = require('jquery');

    require('bootstrap');

} catch (e) {

	console.log('error: ' + e.toString())
}


window.Event   = new Vue

import VueRouter from 'vue-router'
Vue.use(VueRouter)

import Vuelidate from 'vuelidate'
Vue.use(Vuelidate)

import VueToast from 'vue-toast-notification'
Vue.use(VueToast)
import 'vue-toast-notification/dist/theme-sugar.css';

//HELPERS JS
import Helpers from './helpers'
window.helpers 		= new Helpers()

import VueTheMask from 'vue-the-mask'
Vue.use(VueTheMask)

import vue2Swiper from 'vue2-swiper'
Vue.use(vue2Swiper)

import money from 'v-money'
Vue.use(money, {
	precision: 	2,
	prefix: 	'R$ ',
	decimal: 	',',
	thousands: 	'.'
})

import './assets/scss/app.scss'

require('./global-components')
require('./global-variables')
require('./directives')