<template>
	<router-view></router-view>
</template>

<script>
	import Navbar from './sections/Navbar'
	import Footer from './sections/Footer'
	import AOS from 'aos'
	import "aos/dist/aos.css"
	export default {
		created () {
			AOS.init({
				duration: 800,
				easing: 'ease-in-out',
				once: true,
				mirror: false
			})
		},
		name: 'Index',
		components: 	{
		},
		data () {
			return {
				whatsapp_img: 	require('../../assets/img/whatsapp-icon.png'),
				go_top_img:		require('../../assets/img/go-top-icon.png'),
				scrolled:		false,
			}
		},
		computed: {
		},
		methods: {
			scrollTo(element, blank = false)
			{
				// window.scrollTo(element)
			}
		},
		mounted()
		{
			window.onscroll = () => {
				this.scrolled = document.documentElement.scrollTop > 700
			}
		}
	}
</script>

<style lang="css" scoped>
.main
{
	min-height: 64vh;
}
</style>